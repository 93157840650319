// @ts-ignore
import clevertap from "clevertap-web-sdk";

const CLEVERTAP_ACCOUNT_ID = "R99-W66-4R6Z";

clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
clevertap.privacy.push({ useIP: true }); // Set the flag to true, if the user agrees to share their IP data
clevertap.init(CLEVERTAP_ACCOUNT_ID, "us1");
clevertap.spa = true;
clevertap.setLogLevel(3);

export default clevertap;
